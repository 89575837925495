import React from 'react'
import styled from 'styled-components'

const Icon = styled.svg`
  flex-shrink: 0;
  width: 1.3rem;
  height: 1.3rem;
  flex-shrink: 0;
  fill: currentColor;
`

function CloseIcon(props) {
  return (
    <Icon {...props} viewBox="0 0 24 24" fill="currentColor" aria-labelledby="close-icon">
      <g data-name="Layer 2">
        <path
          d="M13.41 12l4.3-4.29a1 1 0 10-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 00-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 000 1.42 1 1 0 001.42 0l4.29-4.3 4.29 4.3a1 1 0 001.42 0 1 1 0 000-1.42z"
          data-name="close"
        />
      </g>
    </Icon>
  )
}

export default CloseIcon
