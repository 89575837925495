import React, { useState, useMemo } from 'react'
import { bool, string, object } from 'prop-types'
import styled from 'styled-components'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { progressState } from '../libs/state'
import {
  reverse,
  sortByDate,
  sortByEscalations,
  sortByKey,
  sortByStatus,
  sortByTickets,
  sortByTotal,
} from '../helpers/sorters'

import ProblemItem from './ProblemItem'
import SortHeader, { Header } from './SortHeader'
import InfoDialog from './InfoDialog'
import EmptyProblemItem from './EmptyProblemItem'

const ListHeader = styled.div`
  top: 0;
  align-self: flex-start;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  background: #f0f2f5;
  padding: 1.5rem 0 0.2rem;
  flex-shrink: 0;
  z-index: 10;
  > h1 {
    letter-spacing: 1px;
    font-weight: bold !important;
  }
`

const SortHeaders = styled.div`
  display: flex;
  padding: 0 42px 0 32px;
  align-items: center;
  margin-top: 2rem;
  .key {
    flex: 0 0 9.5%;
  }
  .type {
    flex: 1 1 auto;
    padding-right: 42px;
  }
  .tickets {
    flex: 0 0 6.8%;
  }
  .escalations {
    flex: 0 0 6.8%;
    > span:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .total {
    flex: 0 0 6.8%;
  }
  .date {
    flex: 0 0 11%;
  }
  .status {
    flex: 0 0 calc(11.5% + 42px);
  }
  &.no-status .date {
    flex: 0 0 calc(22.5% + 42px);
  }
  @media (max-width: 1200px) {
    .tickets {
      flex: 0 0 8%;
    }
    .escalations {
      flex: 0 0 8%;
    }
    .total {
      flex: 0 0 8%;
    }
    .date {
      flex: 0 0 13%;
    }
    &.no-status .date {
      flex: 0 0 calc(24.5% + 42px);
    }
  }
`

const IssueList = ({ title, inactive, listState }) => {
  const issuesLoadable = useRecoilValueLoadable(listState)
  const progress = useRecoilValue(progressState)
  const [sortBy, setSortBy] = useState('key')
  // const [displayList, setDisplayList] = useState(true)
  const [reverseSort, setReverseSort] = useState(false)
  const issues = issuesLoadable.contents && issuesLoadable.contents.length ? issuesLoadable.contents : []
  const loading = issues.length === 0 && progress < 100
  const reverseByDefault = by => {
    switch (by) {
      case 'key':
        return false
      case 'tickets':
      case 'escalations':
      case 'total':
      case 'date':
        return true
      default:
        return false
    }
  }
  const sort = by => {
    if (by === sortBy) {
      setReverseSort(!reverseSort)
    } else {
      setSortBy(by)
      setReverseSort(reverseByDefault(by))
    }
  }
  const getSorter = (by, isReversed) => {
    let sorter
    switch (by) {
      case 'key':
        sorter = sortByKey
        break
      case 'tickets':
        sorter = sortByTickets
        break
      case 'escalations':
        sorter = sortByEscalations
        break
      case 'total':
        sorter = sortByTotal
        break
      case 'date':
        sorter = sortByDate
        break
      default:
        sorter = sortByStatus
    }
    return isReversed ? reverse(sorter) : sorter
  }
  const sortedIssues = useMemo(() => [...issues]
    .sort(getSorter(sortBy, reverseSort))
    .map(i => <ProblemItem key={i.key} problemIssue={i} loading={loading} />))
  return (
    <>
      <ListHeader>
        {/* <button type="button" onClick={() => setDisplayList(!displayList)}> */}
        {/* <ChevronIcon displayList={displayList} /> */}
        <h1>
          {`${title} (${issues.length})`}
          {title === 'Released issues' && (
            <InfoDialog
              title="Released within 30 days"
              text="When a ticket has been marked as released, it will remain in the released issues for 30 days."
            />
          )}
        </h1>
        {/* </button> */}
        <SortHeaders className={title === 'Released issues' && 'no-status'}>
          <SortHeader
            label="Issue key"
            headerKey="key"
            sort={sort}
            reverseSort={reverseSort}
            sortBy={sortBy}
          />
          <Header label="Type" headerKey="type" />
          <SortHeader
            label="Tickets"
            headerKey="tickets"
            sort={sort}
            reverseSort={reverseSort}
            sortBy={sortBy}
            disabled={loading}
          />
          <SortHeader
            label="Escalations"
            headerKey="escalations"
            sort={sort}
            reverseSort={reverseSort}
            sortBy={sortBy}
            tooltip
          />
          <SortHeader
            label="Total"
            headerKey="total"
            sort={sort}
            reverseSort={reverseSort}
            sortBy={sortBy}
            disabled={loading}
          />
          <SortHeader
            label="Date"
            headerKey="date"
            sort={sort}
            reverseSort={reverseSort}
            sortBy={sortBy}
          />
          {title !== 'Released issues' && (
            <SortHeader
              label="Status"
              headerKey="status"
              sort={sort}
              reverseSort={reverseSort}
              sortBy={sortBy}
              disabled={loading}
            />
          )}
        </SortHeaders>
      </ListHeader>
      {loading ? (
        <>
          <EmptyProblemItem inactive={inactive} i={1} />
          <EmptyProblemItem inactive={inactive} i={2} />
          <EmptyProblemItem inactive={inactive} i={3} />
        </>
      ) : (
        sortedIssues
      )}
    </>
  )
}

IssueList.propTypes = {
  title: string.isRequired,
  inactive: bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  listState: object.isRequired,
}

export default IssueList
