import React from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import styled from 'styled-components'

import formatDate from '../libs/formatDate'
import Table from './Table/Table'
import TableRow from './Table/TableRow'
import TableCell from './Table/TableCell'
import LinkIcon from '../icons/LinkIcon'
import { escalationIssueShape } from '../helpers/jira'
import Placeholder from './Placeholder'

const TableWrapper = styled.div`
  padding: 0 32px;
`

const IssueTable = ({ issues, highlight, highlit, loading }) => (
  <TableWrapper>
    <h6>{`OPs Escalation (${issues.length})`}</h6>
    {issues.length > 0 ? (
      <Table
        headers={['Ticket', '', 'Title', 'Category', 'Region', 'Foundation', 'Created', 'Linked']}
        className="issue-table"
      >
        {issues.map(
          ({ category, created, href, key, region, summary, foundation, zendesk, stub }, i) => {
            const isHighlit = highlit.includes(key)
            return (
              <TableRow
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={`table-row${isHighlit ? ' highlit' : ''}`}
                onMouseEnter={() => highlight([key])}
                onMouseLeave={() => highlight([])}
              >
                <TableCell className="key">
                  <a href={href} className="jira-title" target="_blank" rel="noopener noreferrer">
                    {key}
                  </a>
                </TableCell>
                <TableCell className="labels" />
                <TableCell className="summary">{summary}</TableCell>
                <TableCell className="category">
                  {stub ? <Placeholder width="84px" /> : category}
                </TableCell>
                <TableCell className="region">
                  {stub ? <Placeholder width="84px" /> : region}
                </TableCell>
                <TableCell className="foundation">
                  {stub ? <Placeholder width="24px" /> : foundation}
                </TableCell>
                <TableCell className="created">
                  {stub ? <Placeholder width="84px" /> : formatDate(created)}
                </TableCell>
                <TableCell className="linked">
                  {stub || loading ? (
                    <Placeholder width="24px" />
                  ) : (
                    zendesk && zendesk.length > 0 && <LinkIcon />
                  )}
                </TableCell>
              </TableRow>
            )
          },
        )}
      </Table>
    ) : (
      undefined
    )}
  </TableWrapper>
)

IssueTable.propTypes = {
  issues: arrayOf(shape(escalationIssueShape)).isRequired,
  highlight: func.isRequired,
  highlit: arrayOf(string).isRequired,
  loading: bool.isRequired,
}

export default IssueTable
