import React from 'react'
import styled from 'styled-components'
import { bool, func, string } from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { hyphenCase } from 'change-case'
import ChevronIcon from '../icons/ChevronIcon'

const HeaderLayout = styled.label`
  cursor: ${({ sortable = true }) => (sortable ? 'pointer' : 'auto')};
  display: flex;
  font-size: 13px;
  margin-bottom: 8px;
  line-height: 18px;
  color: rgb(95, 94, 112);
  font-family: 'Open sans', sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  &.disabled {
    cursor: default;
  }
  span {
    margin-right: 4px;
    &.chevron {
      margin-right: 0;
      flex: 0 0 24px;
    }
  }
`

const SortHeader = ({ reverseSort, headerKey, label, sort, sortBy, tooltip, disabled }) => (
  <HeaderLayout
    className={`${headerKey}${sortBy === headerKey ? ' selected' : ''}${
      disabled ? ' disabled' : ''
    }`}
    onClick={disabled ? () => {} : () => sort(headerKey)}
  >
    {tooltip ? (
      <span data-tip={label} data-for={`tooltip-sort${hyphenCase(label)}`}>
        {label}
      </span>
    ) : (
      <span>{label}</span>
    )}
    <ChevronIcon
      isOpen={sortBy === headerKey && !reverseSort}
      color={sortBy === headerKey ? '#4786FF' : ''}
    />
    {tooltip && (
      <ReactTooltip
        id={`tooltip-sort${hyphenCase(label)}`}
        place="top"
        type="dark"
        effect="solid"
      />
    )}
  </HeaderLayout>
)

SortHeader.propTypes = {
  reverseSort: bool.isRequired,
  headerKey: string.isRequired,
  label: string.isRequired,
  sort: func.isRequired,
  sortBy: string.isRequired,
  tooltip: bool,
  disabled: bool,
}

SortHeader.defaultProps = { tooltip: false, disabled: false }

export const Header = ({ label, headerKey, tooltip }) => (
  <HeaderLayout sortable={false} className={`${headerKey}`}>
    {tooltip ? (
      <span data-tip={label} data-for={`tooltip-sort${hyphenCase(label)}`}>
        {label}
      </span>
    ) : (
      <span>{label}</span>
    )}
    {tooltip && (
      <ReactTooltip
        id={`tooltip-sort${hyphenCase(label)}`}
        place="top"
        type="dark"
        effect="solid"
      />
    )}
  </HeaderLayout>
)

Header.propTypes = {
  headerKey: string.isRequired,
  label: string.isRequired,
  tooltip: bool,
}

Header.defaultProps = { tooltip: false }

export default SortHeader
