import {
  getDeployIssues,
  getEscalationIssues,
  getProblemStatus,
  transformDeployIssue,
  transformEscalationIssue,
  transformProblemIssue,
} from './jira'
import { zendeskTransform } from './zendesk'

const combineIssues = (problemIssues, relatedIssues, zendeskTickets) => {
  const data = []
  const zdTickets = zendeskTickets.map(t => zendeskTransform(t))
  problemIssues.forEach(i => {
    const issueData = transformProblemIssue(i)
    const deployIssues = getDeployIssues(i)
    const escalationIssues = getEscalationIssues(i)
    issueData.deployIssues = deployIssues.reduce((acc, linkedIssue) => {
      const rIssue = relatedIssues.find(r => r && r.key === linkedIssue.key)
      if (rIssue) {
        const fullIssue = transformDeployIssue(rIssue)
        fullIssue.stub = false
        acc.push(fullIssue)
      } else {
        const stubIssue = transformDeployIssue(linkedIssue)
        stubIssue.stub = true
        acc.push(stubIssue)
      }
      return acc
    }, [])
    issueData.escalationIssues = escalationIssues.reduce((acc, linkedIssue) => {
      const rIssue = relatedIssues.find(r => r && r.key === linkedIssue.key)
      if (rIssue) {
        const zendesk = zdTickets.reduce((tAcc, t) => {
          const linked = t.jira.filter(j => j === linkedIssue.key && !j.startsWith('PF-'))
          return [...tAcc, ...linked]
        }, [])
        const fullIssue = transformEscalationIssue(rIssue)
        fullIssue.stub = false
        acc.push({ ...fullIssue, zendesk })
      } else {
        const stubIssue = transformEscalationIssue(linkedIssue)
        stubIssue.stub = true
        acc.push(stubIssue)
      }
      return acc
    }, [])
    issueData.zendeskTickets = zdTickets.filter(t => {
      let match = false
      t.jira.forEach(j => {
        if (
          j === i.key
          || deployIssues.map(d => d.key).includes(j)
          || escalationIssues.map(e => e.key).includes(j)
        ) {
          match = true
        }
      })
      return match
    })
    issueData.status = getProblemStatus(issueData)
    data.push(issueData)
  })
  return data
}

export default combineIssues
