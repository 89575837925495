export const sortByTickets = (a, b) => a.zendeskTickets.length - b.zendeskTickets.length

export const sortByEscalations = (a, b) => a.escalationIssues.length - b.escalationIssues.length

export const sortByTotal = (a, b) => {
  const aSum = a.zendeskTickets.length + a.escalationIssues.length
  const bSum = b.zendeskTickets.length + b.escalationIssues.length
  return aSum - bSum
}

export const sortByStatus = (a, b) => {
  const statusOrder = [
    { name: 'New', order: 1 },
    { name: 'In progress', order: 2 },
    { name: 'Pending release', order: 3 },
    { name: 'Release scheduled', order: 4 },
    { name: 'Released', order: 5 },
    { name: 'Cancelled', order: 6 },
  ]
  const aMatch = statusOrder.find(o => o.name === a.status)
  const aOrder = (aMatch && aMatch.order) || 7
  const bMatch = statusOrder.find(o => o.name === b.status)
  const bOrder = (bMatch && bMatch.order) || 7

  if (aOrder === bOrder) return 0
  return aOrder - bOrder
}

export const sortByKey = (a, b) => (a.key > b.key ? 1 : -1)

export const sortByDate = (a, b) => {
  const aDate = new Date(a.created)
  const bDate = new Date(b.created)
  if (aDate === bDate) return 0
  return aDate > bDate ? 1 : -1
}

export const reverse = sorter => (a, b) => -sorter(a, b)
