import React from 'react'
import styled from 'styled-components'
import { paramCase } from 'change-case'

export default styled(({ status, className }) => (
  <span className={`${className} ${paramCase(status)}`}>
    {status}
  </span>
))`
  font-size: 12px;
  background: #C9D0E4;
  color: #4A495E;
  border-radius: 2px;
  line-height: 24px;
  padding: 0 8px;
  display: inline-block;
  vertical-align: text-bottom;
  &.new {
    color: #4786ff;
    background: rgba(71, 134, 255, 0.16);
  }
  &.in-progress {
    color: #00C89C;
    background: rgba(0, 200, 156, 0.16);
  }
  &.pending-release {
    color: #FF8359;
    background: rgba(255, 131, 89, 0.16);
  }
  &.release-scheduled {
    color: #4A495E;
    background: rgba(201, 208, 228, 0.16);
  }
  &.released {
    color: #98A0AB;
    background: rgba(201, 208, 228, 0.16);
  }
`
