import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import ChevronIcon from '../icons/ChevronIcon'
import Placeholder from './Placeholder'

const SettingsLineWrapper = styled.div`
  margin-bottom: 8px;
  border-radius: 4px;
  background: #fff;
  &.inactive {
    background: rgba(255, 255, 255, 0.6);
  }
  &.open {
    box-shadow: 0 10px 20px rgba(75, 97, 128, 0.08);
    background: #fff;
    .setting-line {
      position: relative;
      background: #f5f7fa;
      border-radius: 4px 4px 0 0;
      cursor: poiner;
      ${({ inactive }) => (inactive
    ? ''
    : css`
              &:hover {
                background: #f5f7fa;
                box-shadow: none;
                .open-text {
                  display: none;
                }
              }
            `)}
    }
    .action .chevron {
      transform: rotate(180deg);
    }
    .summary {
      .text {
        &:hover {
          .tooltip-content {
            display: block;
          }
        }
      }
    }
  }
  .action {
    flex: 0 0 64px;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
  }
  button {
    height: 32px;
    line-height: 30px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 2px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background: transparent;
    padding: 0;
    &:hover,
    &:focus {
      box-shadow: none;
      background: transparent;
      padding: 0;
      margin: 0;
      outline: none;
      border-width: initial;
      border-style: none;
      border-color: initial;
      border-image: initial;
    }
  }
`
const SettingLineLayout = styled.div`
  display: flex !important;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
  box-sizing: border-box;
  font-size: 16px;
  color: #4a495e;
  border-radius: 4px;
  text-decoration: none;
  width: 100%;
  hr {
    background: #d8e2f0;
  }
  ${({ loading }) => (loading
    ? ''
    : css`
          &:hover {
            background: rgba(71, 134, 255, 0.13);
            box-shadow: 0 10px 20px rgba(75, 97, 128, 0.08);
            .chevron svg path {
              fill: #4786ff;
            }
          }
        `)}
`

const SettingLineInfo = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 64px);
  justify-content: space-between;
  font-size: 15px;
  color: #4b6180;
  strong {
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const SettingLine = ({
  className,
  inactive,
  children,
  id,
  info,
  isOpen,
  onClick,
  openText,
  loading,
}) => (
  <SettingsLineWrapper
    className={`settings-item${isOpen ? ' open' : ''}${className ? ` ${className}` : ''}`}
    inactive={inactive}
  >
    <SettingLineLayout loading={+loading} className="setting-line">
      <SettingLineInfo id={id}>{info}</SettingLineInfo>
      <div className="action">
        {loading ? (
          <Placeholder width="24px" />
        ) : (
          <button
            id={`${id}-edit`}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              onClick()
              document.activeElement.blur()
            }}
            aria-label={openText}
            type="button"
          >
            <ChevronIcon isOpen={isOpen} />
          </button>
        )}
      </div>
    </SettingLineLayout>
    {children}
  </SettingsLineWrapper>
)

SettingLine.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  info: PropTypes.node.isRequired,
  inactive: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  openText: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
}

SettingLine.defaultProps = {
  children: undefined,
  isOpen: false,
  className: '',
  loading: false,
  openText: '',
  inactive: false,
}

export default SettingLine
