import React from 'react'
import styled from 'styled-components'
import { bool, number } from 'prop-types'

import SettingLine from './SettingLine'
import { semiBoldAlias } from '../libs/theme'
import Placeholder from './Placeholder'

const InfoLayout = styled.div`
  width: 100%;
  display: flex;
  font-size: 13px;
  color: #5f5e70;
  align-items: center;
  padding: 0 32px;
  ${semiBoldAlias()};
  &.inactive {
    > div {
      opacity: 0.8;
    }
  }
  .key {
    flex: 0 0 10%;
    font-size: 15px;
    font-weight: bold;
  }
  .type {
    flex: 0 0 10%;
    font-size: 15px;
    font-weight: bold;
  }
  .summary {
    color: #302f45;
    flex: 1 1 auto;
    max-lines: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 15px;
    display: flex;
    .text {
      flex: 0 1 auto;
    }
    .workaround {
      flex: 0 0 45px;
      padding-left: 16px;
      .workaround-lozenge {
        cursor: pointer;
        background: #f0f2f5;
        color: #98a0ab;
        border-radius: 2px;
        padding: 0 8px;
        line-height: 24px;
        font-size: 13px;
        display: inline-block;
      }
    }
  }
  .tickets {
    flex: 0 0 7%;
  }
  .escalations {
    flex: 0 0 7%;
  }
  .total {
    flex: 0 0 7%;
  }
  .created {
    flex: 0 0 11%;
  }
  .status {
    flex: 0 0 11.5%;
  }
  @media (max-width: 1200px) {
    .key {
      flex: 0 0 10%;
    }
    .type {
      flex: 0 0 11%;
    }
    .tickets {
      flex: 0 0 8%;
    }
    .escalations {
      flex: 0 0 8%;
    }
    .total {
      flex: 0 0 8%;
    }
    .created {
      flex: 0 0 13%;
    }
    .status {
      flex: 0 0 11.5%;
    }
  }
`

const EmptyProblemItem = ({ inactive, i }) => (
  <SettingLine
    id={`empty-problem-${i}`}
    className={inactive ? 'inactive' : ''}
    inactive={inactive}
    loading
    info={(
      <InfoLayout className={inactive ? 'inactive' : ''}>
        <div className="key">
          <Placeholder width="66px" />
        </div>
        <div className="type">
          <Placeholder width="55px" />
        </div>
        <div className="summary">
          <Placeholder width="262px" />
        </div>
        <div className="tickets">
          <Placeholder width="24px" />
        </div>
        <div className="escalations">
          <Placeholder width="24px" />
        </div>
        <div className="total">
          <Placeholder width="24px" />
        </div>
        <div className="created">
          <Placeholder width="82px" />
        </div>
        <div className="status">
          <Placeholder width="83px" />
        </div>
      </InfoLayout>
    )}
    onClick={() => {}}
  />
)

EmptyProblemItem.propTypes = { inactive: bool.isRequired, i: number.isRequired }

export default EmptyProblemItem
