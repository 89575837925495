import React from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import { progressState } from '../libs/state'

const LoadingBarEl = styled.div`
  flex-shrink: 0;
  margin: 0 0 -4px;
  height: 4px;
  position: relative;
  background: #c9d0e4;
  overflow: hidden;
  z-index: 20;
  > span {
    display: block;
    height: 100%;
    background: #4786ff;
    position: absolute;
    overflow: hidden;
    width: ${props => props.progress}%;
    transition: width 0.5s linear;
  }
`

export default function LoadingBar() {
  const progress = useRecoilValue(progressState)
  if (progress >= 100) {
    return null
  }
  return (
    <LoadingBarEl progress={progress}>
      <span className="meter" />
    </LoadingBarEl>
  )
}
