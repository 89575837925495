import React from 'react'
import { string } from 'prop-types'

import useUniqueId from '../libs/useUniqueId'

const LinkIcon = ({ color }) => {
  const uid = useUniqueId()

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`link-icon-${uid}`}
    >
      <title id={`link-icon-${uid}`}>Link icon</title>
      <g clipPath={`url(#clip0-${uid})`}>
        <path
          d="M9.19236 14.1421C8.80345 13.7532 8.80345 13.1168 9.19236 12.7279C9.58127 12.339 10.2177 12.339 10.6066 12.7279C11.7733 13.8946 13.6825 13.8946 14.8492 12.7279L18.3847 9.19239C19.5515 8.02566 19.5515 6.11647 18.3847 4.94975C17.218 3.78302 15.3088 3.78302 14.1421 4.94975L12.7279 6.36396C12.339 6.75287 11.7026 6.75287 11.3137 6.36396C10.9248 5.97505 10.9248 5.33866 11.3137 4.94975L12.7279 3.53553C14.6795 1.58392 17.8473 1.58392 19.799 3.53553C21.7506 5.48715 21.7506 8.65499 19.799 10.6066L16.2634 14.1421C14.3118 16.0938 11.144 16.0938 9.19236 14.1421Z"
          fill={color}
          className="svg-fill"
        />
        <path
          d="M3.53562 19.799C1.58401 17.8474 1.58401 14.6795 3.53562 12.7279L7.07116 9.19239C9.02277 7.24078 12.1906 7.24078 14.1422 9.19239C14.5311 9.5813 14.5311 10.2177 14.1422 10.6066C13.7533 10.9955 13.1169 10.9955 12.728 10.6066C11.5613 9.43988 9.6521 9.43988 8.48537 10.6066L4.94984 14.1421C3.78311 15.3089 3.78311 17.2181 4.94984 18.3848C6.11656 19.5515 8.02575 19.5515 9.19248 18.3848L10.6067 16.9706C10.9956 16.5817 11.632 16.5817 12.0209 16.9706C12.4098 17.3595 12.4098 17.9959 12.0209 18.3848L10.6067 19.799C8.65507 21.7506 5.48724 21.7506 3.53562 19.799Z"
          fill={color}
          className="svg-fill"
        />
      </g>
      <defs>
        <clipPath id={`clip0-${uid}`}>
          <rect
            width="10"
            height="23"
            fill="white"
            transform="translate(16.2634) rotate(45)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

LinkIcon.propTypes = { color: string }
LinkIcon.defaultProps = { color: '#C9D0E4' }

export default LinkIcon
