import styled, { css } from 'styled-components'
import { semiBoldAlias } from '../../libs/theme'

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
  svg {
    height: auto;
    width: 100%;
    > path {
      fill: #98a0ab;
    }
  }
  &:hover,
  &:focus,
  &:focus-within {
    outline: none;
    .dialog {
      display: inline-block;
    }
    svg > path {
      fill: #5f5e70;
    }
  }
  ${props => props.isHover
    && css`
      svg > path {
        fill: #5f5e70 !important;
      }
    `};
`

export const DialogContent = styled.div`
  background: #ffffff;
  border-radius: 2px;
  width: 300px;
  box-shadow: 0 6px 24px rgba(75, 97, 128, 0.32);
  padding: 16px;
  line-height: 22px;
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  z-index: 9;
  letter-spacing: 0;
  box-sizing: border-box;

  a {
    color: #4786ff;
    text-decoration: none;
    padding: 0 8px;
    margin: 0 -8px -6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    box-sizing: border-box;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: none;
      border: 1px solid rgb(22, 102, 255);
      border-radius: 4px;
      box-shadow: 0 0 2px rgb(22, 102, 255);
      text-decoration: none;
      margin: -1px -9px -7px;
    }
    &:active {
      border: none;
      box-shadow: none;
      text-decoration: underline;
      margin: 0 -8px;
    }
  }
  a + a {
    margin-left: 16px;
    &:focus {
      margin-left: 15px;
    }
    &:active {
      margin-left: 16px;
    }
  }
`

export const DialogWrapper = styled.div`
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  padding-top: 8px;
  z-index: 9;
  &.align-left {
    left: 0;
  }
  &.align-right {
    left: auto;
    right: 0;
  }
  &.align-center {
    left: 12px;
    margin-left: -150px;
  }
`

export const Title = styled.div`
  margin-bottom: 4px;
  white-space: normal;
  color: #4a495e;
  ${semiBoldAlias};
`

export const Text = styled.div`
  white-space: normal;
  color: rgb(36, 36, 40);
`
