import styled from 'styled-components'
import { string } from 'prop-types'

const Placeholder = styled.div.attrs(() => ({}))`
  border-radius: 4px;
  height: 24px;
  width: ${props => props.width};
  background: rgba(201, 208, 228, 0.8)
    linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f5f7fa 10%, rgba(255, 255, 255, 0) 20%)
    no-repeat fixed;
  animation: Animation 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  @keyframes Animation {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  &.light {
    background-color: #ffffff;
  }
`

Placeholder.propTypes = { width: string.isRequired }

export default Placeholder
