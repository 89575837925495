import React from 'react'
import styled from 'styled-components'

const Icon = styled.svg`
  width: 1.3rem;
  height: 1.3rem;
  flex-shrink: 0;
  color: #4a495e;
`

function SearchIcon(props) {
  return (
    <Icon {...props} viewBox="0 0 24 24" height="48" width="48" fill="currentColor">
      <path d="M10 18a7.952 7.952 0 004.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0018 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z" />
    </Icon>
  )
}

export default SearchIcon
