import React from 'react'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { gridOrDedicatedFilterState } from '../libs/state'

const Container = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 2rem;
  background: #1a192b;
  width: 100%;
  flex-shrink: 0;
  padding: 0.3rem 40px 0;
  button {
    letter-spacing: 3px;
    margin-right: 1.8rem;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0.2rem 0.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    p {
      color: #fff;
      text-transform: uppercase;
    }
    span {
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
      &.selected {
        background-color: #fff;
      }
    }
    &:focus {
      outline: 0;
    }
  }
`

export default function GridOrDedicatedTabs() {
  const [filter, setFilter] = useRecoilState(gridOrDedicatedFilterState)
  return (
    <Container>
      <button type="button" onClick={() => setFilter('')}>
        <p>All</p>
        <span className={filter === '' ? 'selected' : undefined} />
      </button>
      <button type="button" onClick={() => setFilter('Grid')}>
        <p>Grid</p>
        <span className={filter === 'Grid' ? 'selected' : undefined} />
      </button>
      <button type="button" onClick={() => setFilter('Dedicated')}>
        <p>Dedicated</p>
        <span className={filter === 'Dedicated' ? 'selected' : undefined} />
      </button>
    </Container>
  )
}

GridOrDedicatedTabs.propTypes = {}
