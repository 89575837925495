import React from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import styled from 'styled-components'

import { zendeskShape } from '../helpers/zendesk'
import formatDate from '../libs/formatDate'
import Table from './Table/Table'
import TableRow from './Table/TableRow'
import TableCell from './Table/TableCell'
import LinkIcon from '../icons/LinkIcon'
import Placeholder from './Placeholder'

const TableWrapper = styled.div`
  padding: 0 32px;
  border-bottom: 1px solid #c9d0e4;
`

const TicketTable = ({ tickets, highlight, highlit, loading }) => (
  <TableWrapper>
    <h6>{`Zendesk (${tickets.length})`}</h6>
    <Table
      headers={['Ticket', '', 'Title', 'Cloud', 'Region', 'Support plan', 'Created', 'Linked']}
    >
      {!loading ? (
        tickets.map(({ cloud, created, href, id, region, summary, supportPlan, jira }, i) => {
          const isHighlit = jira.find(j => highlit.includes(j))
          return (
            <TableRow
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={`table-row${isHighlit ? ' highlit' : ''}`}
              onMouseEnter={() => highlight(jira)}
              onMouseLeave={() => highlight([])}
            >
              <TableCell className="id">
                <a href={href} className="zendesk-title" target="_blank" rel="noopener noreferrer">
                  {id}
                </a>
              </TableCell>
              <TableCell className="labels" />
              <TableCell className="summary">{summary}</TableCell>
              <TableCell className="cloud">{cloud}</TableCell>
              <TableCell className="region">{region}</TableCell>
              <TableCell className="support-plan">{supportPlan}</TableCell>
              <TableCell className="created">{formatDate(created)}</TableCell>
              <TableCell className="linked">{jira.length > 0 && <LinkIcon />}</TableCell>
            </TableRow>
          )
        })
      ) : (
        <>
          <TableRow>
            <TableCell className="id">
              <Placeholder width="66px" />
            </TableCell>
            <TableCell className="label">
              <Placeholder width="66px" />
            </TableCell>
            <TableCell className="summary">
              <Placeholder width="332px" />
            </TableCell>
            <TableCell className="cloud">
              <Placeholder width="84px" />
            </TableCell>
            <TableCell className="region">
              <Placeholder width="84px" />
            </TableCell>
            <TableCell className="support-plan">
              <Placeholder width="83px" />
            </TableCell>
            <TableCell className="created">
              <Placeholder width="84px" />
            </TableCell>
            <TableCell className="linked">
              <Placeholder width="24px" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="id">
              <Placeholder width="66px" />
            </TableCell>
            <TableCell className="summary">
              <Placeholder width="332px" />
            </TableCell>
            <TableCell className="cloud">
              <Placeholder width="84px" />
            </TableCell>
            <TableCell className="region">
              <Placeholder width="84px" />
            </TableCell>
            <TableCell className="support-plan">
              <Placeholder width="83px" />
            </TableCell>
            <TableCell className="created">
              <Placeholder width="84px" />
            </TableCell>
            <TableCell className="linked">
              <Placeholder width="24px" />
            </TableCell>
          </TableRow>
        </>
      )}
    </Table>
  </TableWrapper>
)

TicketTable.propTypes = {
  tickets: arrayOf(shape(zendeskShape)).isRequired,
  highlight: func.isRequired,
  highlit: arrayOf(string).isRequired,
  loading: bool.isRequired,
}

export default TicketTable
