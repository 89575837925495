import { atom, selector, atomFamily } from 'recoil'
import * as localForage from 'localforage'
import combineIssues from '../helpers/combineIssues'
import { inactiveStatuses } from '../helpers/jira'

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'lighthouse',
  version: 1.0,
  storeName: 'issues',
  description: 'some description',
})

export const searchFilterState = atom({
  key: 'searchFilterState',
  default: '',
})

export const progressState = atom({
  key: 'progressState',
  default: 0,
})

export const gridOrDedicatedFilterState = atom({
  key: 'gridOrDedicatedFilterState',
  default: '',
})

const localForageEffect = key => ({ setSelf, onSet }) => {
  setSelf(
    localForage.getItem(key).then(savedValue => (savedValue != null ? JSON.parse(savedValue) : [])),
  )

  onSet(newValue => {
    if (newValue) {
      localForage.setItem(key, JSON.stringify(newValue))
    }
  })
}

export const problemIssuesState = atom({
  key: 'problemIssuesState',
  default: selector({
    key: 'problemIssuesState/Default',
    get: async () => {
      const savedValue = await localForage.getItem('problemIssuesState')
      return JSON.parse(savedValue)
    },
  }),
  effects_UNSTABLE: [localForageEffect('problemIssuesState')],
})
export const relatedIssuesState = atom({
  key: 'relatedIssuesState',
  default: selector({
    key: 'relatedIssuesState/Default',
    get: async () => {
      const savedValue = await localForage.getItem('relatedIssuesState')
      return JSON.parse(savedValue)
    },
  }),
  effects_UNSTABLE: [localForageEffect('relatedIssuesState')],
})
export const zendeskTicketsState = atom({
  key: 'zendeskTicketsState',
  default: selector({
    key: 'zendeskTicketsState/Default',
    get: async () => {
      const savedValue = await localForage.getItem('zendeskTicketsState')
      return JSON.parse(savedValue)
    },
  }),
  effects_UNSTABLE: [localForageEffect('zendeskTicketsState')],
})

export const combinedIssueDataState = selector({
  key: 'combinedIssueDataState',
  get: ({ get }) => {
    const problemIssues = get(problemIssuesState)
    const relatedIssues = get(relatedIssuesState)
    const zendeskTickets = get(zendeskTicketsState)
    return combineIssues(problemIssues, relatedIssues, zendeskTickets)
  },
})

export const availableStatusFiltersState = selector({
  key: 'availableStatusFiltersState',
  get: ({ get }) => {
    const combinedIssueData = get(combinedIssueDataState)
    return [...new Set(combinedIssueData.map(item => item.status))]
  },
})

export const availableLabelFiltersState = selector({
  key: 'availableLabelFiltersState',
  get: ({ get }) => {
    const combinedIssueData = get(combinedIssueDataState)
    return [...new Set(combinedIssueData.flatMap(item => item.labels))]
  },
})

export const statusFiltersState = atom({
  key: 'statusFiltersState',
  default: [],
})

export const labelFiltersState = atom({
  key: 'labelFiltersState',
  default: [],
})

export const statusFilterState = atomFamily({
  key: 'statusFilterState',
  default: false,
})

export const labelFilterState = atomFamily({
  key: 'labelFilterState',
  default: false,
})

export const filteredActiveIssuesState = selector({
  key: 'filteredActiveIssuesState',
  get: ({ get }) => {
    const gridOrDedicatedFilter = get(gridOrDedicatedFilterState)
    const combinedIssueData = get(combinedIssueDataState)
    const labelsFilters = get(labelFiltersState)
    const availableLabelFilters = get(availableLabelFiltersState)
    const statusFilters = get(statusFiltersState)
    const availableStatusFilters = get(availableStatusFiltersState)
    const searchFilter = get(searchFilterState)
    return (
      combinedIssueData
        .filter(i => !inactiveStatuses.includes(i.status))
        .filter(
          i => gridOrDedicatedFilter === ''
            || (gridOrDedicatedFilter === 'Grid' && i.productType === 'Grid')
            || (gridOrDedicatedFilter === 'Dedicated' && i.productType === 'Dedicated'),
        )
        .filter(i => {
          if (searchFilter === '') {
            return true
          }
          if (
            i.key.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
            || i.summary.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
          ) {
            return true
          }
          return false
        })
        .filter(i => (statusFilters.length && statusFilters.length !== availableStatusFilters.length
          ? statusFilters.indexOf(i.status) >= 0
          : true))
        .filter(i => (labelsFilters.length && labelsFilters.length !== availableLabelFilters.length
          ? i.labels.some(label => labelsFilters.indexOf(label) >= 0)
          : true)) || []
    )
  },
})

export const filteredInactiveIssuesState = selector({
  key: 'filteredInactiveIssuesState',
  get: ({ get }) => {
    const gridOrDedicatedFilter = get(gridOrDedicatedFilterState)
    const combinedIssueData = get(combinedIssueDataState)
    const labelsFilters = get(labelFiltersState)
    const availableLabelFilters = get(availableLabelFiltersState)
    const statusFilters = get(statusFiltersState)
    const availableStatusFilters = get(availableStatusFiltersState)
    const searchFilter = get(searchFilterState)
    return (
      combinedIssueData
        .filter(i => inactiveStatuses.includes(i.status))
        .filter(
          i => gridOrDedicatedFilter === ''
            || (gridOrDedicatedFilter === 'Grid' && i.productType === 'Grid')
            || (gridOrDedicatedFilter === 'Dedicated' && i.productType === 'Dedicated'),
        )
        .filter(i => {
          if (searchFilter === '') {
            return true
          }
          if (
            i.key.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
            || i.summary.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1
          ) {
            return true
          }
          return false
        })
        .filter(i => (statusFilters.length && statusFilters.length !== availableStatusFilters.length
          ? statusFilters.indexOf(i.status) >= 0
          : true))
        .filter(i => (labelsFilters.length && labelsFilters.length !== availableLabelFilters.length
          ? i.labels.some(label => labelsFilters.indexOf(label) >= 0)
          : true)) || []
    )
  },
})
