import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useRecoilCallback,
  useRecoilValueLoadable,
} from 'recoil'
import debounce from 'lodash/debounce'
import { func, bool, object, string } from 'prop-types'

import SearchIcon from '../icons/SearchIcon'
import FilterIcon from '../icons/FilterIcon'
import LoadingIcon from '../icons/LoadingIcon'
import CloseIcon from '../icons/CloseIcon'
import {
  searchFilterState,
  progressState,
  availableStatusFiltersState,
  availableLabelFiltersState,
  statusFiltersState,
  labelFiltersState,
  statusFilterState,
  labelFilterState,
} from '../libs/state'
import { useOnClickOutside } from '../helpers/hooks'

const Container = styled.div`
  position: absolute;
  top: 3.4rem;
  right: 40px;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 20;
  height: 0;
  .loading {
    color: #888;
    margin-right: 0.8rem;
  }
  .search {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      outline: none;
      border: none;
      color: #888;
      margin-right: 0.2rem;
      cursor: pointer;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    form {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

const AnimatedInput = styled.input`
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  transition: min-width 0.2s ease, border 0.2s ease;
  height: 2rem;
  font-size: 1rem;
  text-align: left;
  min-width: 0;
  max-width: 5rem;
  outline: none;
  border-bottom: 2px solid transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #4a495e;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #4a495e;
  }

  :-ms-input-placeholder {
    color: #4a495e;
  }

  &:focus {
    text-overflow: clip;
    white-space: normal;
    overflow: auto;
    min-width: 12rem;
    border-bottom: 2px solid #ccc;
  }
`

const FilterButton = styled.button`
  margin-left: 1rem;
  outline: none;
  border: none;
  color: #888;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0;
  &:focus {
    && {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
  > span {
    display: flex;
    align-items: baseline;
    color: #4a495e;
    font-size: 1rem;
  }
  svg {
    margin-top: auto;
    margin-left: 0.8rem;
  }
  &:focus {
    border-color: rgb(22, 102, 255);
    box-shadow: rgb(22 102 255) 0px 0px 2px;
  }
  ${({ isPopoverOpen }) => (isPopoverOpen
    ? css`
          > span {
            color: #4786ff;
          }
          svg {
            color: #4786ff;
          }
        `
    : '')}
`

const FilterButtonLayout = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function Filters() {
  return (
    <Container>
      <SearchFilter />
      <StatusAndLabelsFilter />
    </Container>
  )
}

function SearchFilter() {
  const setSearchFilter = useSetRecoilState(searchFilterState)
  const progress = useRecoilValue(progressState)
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const inputRef = useRef(null)
  const handleSubmit = e => {
    e.preventDefault()
  }
  const handleInput = e => {
    setValue(e.target.value)
  }
  const handleSearchButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }
  const handleFocus = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.selectionStart = value.length
        inputRef.current.selectionEnd = value.length
      }
    }, 0)
  }
  const updateSearchFilter = () => {
    setSearchFilter(value)
    setLoading(false)
  }
  const debounced = debounce(updateSearchFilter, 1000, {
    maxWait: 1000,
    trailing: true,
  })
  useEffect(() => {
    setLoading(true)
    if (progress >= 100) {
      debounced()
    } else {
      setLoading(false)
    }
    return debounced.cancel
  }, [value, progress])
  return (
    <div className="search">
      {progress >= 100 && loading ? (
        <div className="loading">
          <LoadingIcon />
        </div>
      ) : (
        <button onClick={handleSearchButtonClick} type="button">
          <SearchIcon />
        </button>
      )}
      <form onSubmit={handleSubmit}>
        <AnimatedInput
          placeholderTextColor="4a495e"
          ref={inputRef}
          type="text"
          onFocus={handleFocus}
          value={value}
          onChange={handleInput}
          placeholder="Search"
        />
      </form>
    </div>
  )
}

SearchFilter.propTypes = {}

function StatusAndLabelsFilter() {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const handleMenuToggle = () => setIsPopoverOpen(!isPopoverOpen)
  const ref = useRef()
  useOnClickOutside(ref, () => setIsPopoverOpen(false))
  return (
    <>
      <FilterButtonLayout ref={ref}>
        <FilterButton isPopoverOpen={isPopoverOpen} onClick={handleMenuToggle}>
          <span>
            <span>Filter</span>
            <FilterIcon />
          </span>
        </FilterButton>
        <FiltersPopover isOpen={isPopoverOpen} onClose={handleMenuToggle} />
      </FilterButtonLayout>
    </>
  )
}
StatusAndLabelsFilter.propTypes = {}

const PopoverLayout = styled.div`
  position: absolute;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  margin-top: 4px;
  right: 0px;
  top: 100%;
  min-width: 18rem;
  width: fit-content;
  font-size: 14px;
  z-index: 2;
  box-shadow: rgb(75 97 128 / 32%) 0px 6px 24px;
  border-radius: 2px 0px 2px 2px;
  background: rgb(255, 255, 255);
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  -webkit-font-smoothing: antialiased;
  .header {
    padding: 0.6rem 0.8rem 0.6rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      white-space: nowrap;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.8rem;
      color: #4a495e;
      margin: 0;
    }
    button {
      padding: 0.3rem;
      display: flex;
      justify-content: center;
      align-items: stretch;
      outline: none;
      border: none;
      background: transparent;
      cursor: pointer;
      &:hover {
        background: #ededed;
      }
      &:focus {
        border-color: rgb(22, 102, 255);
        box-shadow: rgb(22 102 255) 0px 0px 2px;
      }
      svg {
        fill: #98a0ab;
      }
    }
  }
  hr {
    width: 100%;
    height: 1px;
    background: #c9d0e4;
    opacity: 0.3;
    margin: 0;
  }
  .scrollable-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    max-height: 50vh;
    overflow-y: auto;
    -moz-box-shadow: inset 0 -5px 5px -5px #000000;
    -webkit-box-shadow: inset 0 -5px 5px -5px #000000;
    box-shadow: inset 0 -5px 5px -10px #000000;
    .section {
      padding: 0 0.8rem 0.6rem 1.2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .title {
        background: white;
        width: 100%;
        margin: 0;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.8rem;
        color: #6a696e;
        padding: 0.6rem 0 0.6rem;
        position: sticky;
        top: 0;
        left: 0;
      }
      .filter-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        &:hover {
          background: #fdfdfd;
        }
        label {
          color: #38485e;
          font-size: 1rem;
          user-select: none;
          flex-grow: 1;
          cursor: inherit;
          padding: 0.6rem 0 0.6rem 0.4rem;
          &:focus {
            input[type='checkbox'] {
              box-shadow: rgb(22 102 255) 0px 0px 2px;
            }
          }
        }
        input[type='checkbox'] + label {
          /* &.half {
            color: #4786ff;
          } */
          /* https://lab.plat.farm/accounts/lighthouse/-/merge_requests/6#note_353897 */
        }
        input[type='checkbox']:checked + label {
          color: #4786ff;
        }
        input[type='checkbox'] {
          cursor: inherit;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          display: inline-block;
          width: 1.3rem;
          height: 1.3rem;
          padding: 2px;
          /* &.half {
            padding: 2px 2px 0.4rem 2px;
            background-color: #4786ff;
            border: 1.5px solid #4786ff;
          } */
          background-clip: content-box;
          border: 1.5px solid #bbbbbb;
          border-radius: 3px;
          background-color: transparent;
          &:checked {
            background-color: #4786ff;
            border: 1.5px solid #4786ff;
          }
        }
      }
    }
  }
`

function FiltersPopover({ onClose, isOpen }) {
  return (
    <PopoverLayout isOpen={isOpen}>
      <div className="header">
        <p>Filter Issues</p>
        <button type="button" onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <hr />
      <div className="scrollable-container">
        <Filter
          title="Status"
          availableFiltersState={availableStatusFiltersState}
          filtersState={statusFiltersState}
          filterState={statusFilterState}
        />
        <hr />
        <Filter
          title="Labels"
          availableFiltersState={availableLabelFiltersState}
          filtersState={labelFiltersState}
          filterState={labelFilterState}
        />
      </div>
    </PopoverLayout>
  )
}

FiltersPopover.propTypes = {
  onClose: func.isRequired,
  isOpen: bool.isRequired,
}

function Filter({ title, availableFiltersState, filtersState, filterState }) {
  const availableFiltersLoadable = useRecoilValueLoadable(availableFiltersState)
  const availableFilters = availableFiltersLoadable.contents && availableFiltersLoadable.contents.length
    ? availableFiltersLoadable.contents
    : []
  return (
    <div className="section">
      <p className="title">{title}</p>
      <ShowAllOptionsCheckbox
        label={title}
        availableFiltersState={availableFiltersState}
        filtersState={filtersState}
        filterState={filterState}
      />
      {availableFilters.map(filterLabel => (
        <Checkbox
          key={`${title}-${filterLabel}`}
          filterTypeLabel={title}
          label={filterLabel}
          filtersState={filtersState}
          filterState={filterState}
          availableFiltersState={availableFiltersState}
        />
      ))}
    </div>
  )
}

Filter.propTypes = {
  title: string.isRequired,
  availableFiltersState: object.isRequired,
  filtersState: object.isRequired,
  filterState: func.isRequired,
}

function Checkbox({ label, filterTypeLabel, filtersState, filterState }) {
  const [isFilterChecked, setFilterState] = useRecoilState(filterState(label))
  const handleOnChange = useRecoilCallback(({ set }) => async e => {
    setFilterState(e.target.checked)
    set(filtersState, filters => {
      if (e.target.checked) {
        return [...filters, label]
      }
      const labelIndex = filters.indexOf(label)
      if (labelIndex > -1) {
        return filters.slice(0, labelIndex).concat(filters.slice(labelIndex + 1, filters.length))
      }
      return filters
    })
  })
  return (
    <div className="filter-container">
      <input
        checked={isFilterChecked}
        onChange={handleOnChange}
        type="checkbox"
        id={`filter-${filterTypeLabel}-${label}`}
      />
      <label htmlFor={`filter-${filterTypeLabel}-${label}`}>{label}</label>
    </div>
  )
}

Checkbox.propTypes = {
  label: string.isRequired,
  filterTypeLabel: string.isRequired,
  filtersState: object.isRequired,
  filterState: func.isRequired,
}

function ShowAllOptionsCheckbox({ label, availableFiltersState, filtersState, filterState }) {
  const filters = useRecoilValue(filtersState)
  const availableFiltersLoadable = useRecoilValueLoadable(availableFiltersState)
  const availableFilters = availableFiltersLoadable.contents && availableFiltersLoadable.contents.length
    ? availableFiltersLoadable.contents
    : []
  const isChecked = filters.length === availableFilters.length
  const handleOnChange = useRecoilCallback(({ set }) => async e => {
    set(filtersState, e.target.checked ? [...availableFilters] : [])
    availableFilters.forEach(availableFilterLabel => {
      set(filterState(availableFilterLabel), e.target.checked)
    })
  })
  return (
    <div className="filter-container">
      <input
        checked={isChecked}
        onChange={handleOnChange}
        type="checkbox"
        id={`show-all-${label}`}
      />
      <label htmlFor={`show-all-${label}`}>Show all</label>
    </div>
  )
}

ShowAllOptionsCheckbox.propTypes = {
  label: string.isRequired,
  availableFiltersState: object.isRequired,
  filtersState: object.isRequired,
  filterState: func.isRequired,
}
