import React from 'react'

import styled from 'styled-components'
import LoadingBar from './components/LoadingBar'
import GridOrDedicatedTabs from './components/GridOrDedicatedTabs'
import Filters from './components/Filters'
import IssueList from './components/IssueList'
import InitialLoader from './components/InitialLoader'
import { semiBoldAlias } from './libs/theme'
import { filteredInactiveIssuesState, filteredActiveIssuesState } from './libs/state'

const BodyLayout = styled.div`
  padding: 0px 40px 40px;
  font-family: 'Open Sans', sans-serif;
  flex-grow: 1;
  overflow-y: auto;
  h1 {
    font-size: 20px;
    line-height: 33px;
    ${semiBoldAlias('bold')};
    margin: 0.8rem 0;
  }
  a {
    color: #4786ff;
    text-decoration: none;
  }
`

const Lighthouse = () => (
  <>
    <InitialLoader />
    <GridOrDedicatedTabs />
    <LoadingBar />
    <BodyLayout>
      <Filters />
      <IssueList title="Ongoing issues" inactive={false} listState={filteredActiveIssuesState} />
      <IssueList title="Inactive issues" inactive listState={filteredInactiveIssuesState} />
    </BodyLayout>
  </>
)

export default Lighthouse
