import React from 'react'
import PropTypes from 'prop-types'

const PlatformIcon = ({ color, alt }) => (
  <svg
    alt={alt}
    width="40"
    height="40"
    viewBox="0 0 28 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <polygon id="path-1" points="0 0 27.6677333 0 27.6677333 11.0021333 0 11.0021333" />
      <polygon
        id="path-3"
        points="0 0.0373333333 27.6677333 0.0373333333 27.6677333 3.7744 0 3.7744"
      />
      <polygon
        id="path-5"
        points="0 0.365866667 27.6677333 0.365866667 27.6677333 6.01066667 0 6.01066667"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-54.000000, -17.000000)">
        <g>
          <g transform="translate(54.000000, 17.000000)">
            <g>
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g />
              <polygon
                fill={color}
                mask="url(#mask-2)"
                points="-1.86666667 12.8688 29.5344 12.8688 29.5344 -1.86666667 -1.86666667 -1.86666667"
              />
            </g>
            <g transform="translate(0.000000, 23.893333)">
              <mask id="mask-4" fill="white">
                <use xlinkHref="#path-3" />
              </mask>
              <polygon
                fill={color}
                mask="url(#mask-4)"
                points="-1.86666667 5.64106667 29.5344 5.64106667 29.5344 -1.82933333 -1.86666667 -1.82933333"
              />
            </g>
            <g transform="translate(0.000000, 14.186667)">
              <mask id="mask-6" fill="white">
                <use xlinkHref="#path-5" />
              </mask>
              <polygon
                fill={color}
                mask="url(#mask-6)"
                points="-1.86666667 7.87733333 29.5344 7.87733333 29.5344 -1.5008 -1.86666667 -1.5008"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

PlatformIcon.propTypes = {
  color: PropTypes.string,
  alt: PropTypes.string,
}

PlatformIcon.defaultProps = {
  color: '#fff',
  alt: 'icon',
}

export default PlatformIcon
