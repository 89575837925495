import React, { useState } from 'react'
import PropTypes from 'prop-types'

import InfoIcon from '../../icons/InfoIcon'

import * as S from './styles'

const InfoDialog = ({
  align,
  className,
  icon,
  text,
  title,
}) => {
  const [isHover, setIsHover] = useState(false)

  const handleKeyUp = event => {
    if (event.which === 27) document.activeElement.blur()
  }

  return (
    <S.Wrapper
      className={`info-dialog${className ? ` ${className}` : ''}`}
      tabIndex="0"
      onKeyUp={handleKeyUp}
      isHover={isHover}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {icon || <InfoIcon />}
      {(title || text) && (
        <S.DialogWrapper
          align={align}
          className={`dialog${align ? ` align-${align}` : ''}`}
        >
          <S.DialogContent>
            {title && <S.Title>{title}</S.Title>}
            {text && <S.Text>{text}</S.Text>}
          </S.DialogContent>
        </S.DialogWrapper>
      )}
    </S.Wrapper>
  )
}

InfoDialog.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
}

InfoDialog.defaultProps = {
  align: 'left',
  className: '',
}

export default InfoDialog
