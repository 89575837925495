import axios from 'axios'
import { bool, string } from 'prop-types'
import { getAccessToken } from './auth'

export const transformProblemIssue = i => ({
  key: i.key,
  href: `https://platformsh.atlassian.net/browse/${i.key}`,
  summary: i.fields.summary,
  created: i.fields.created,
  status: i.fields.status.name,
  labels: i.fields.labels,
  workaround: i.fields.customfield_13285 && i.fields.customfield_13285.value === 'Yes',
  productType:
    i.fields.customfield_13298 && i.fields.customfield_13298.value
      ? i.fields.customfield_13298.value
      : '',
})

export const transformDeployIssue = i => ({
  key: i.key,
  href: `https://platformsh.atlassian.net/browse/${i.key}`,
  summary: i.fields.summary,
  status: i.fields.status.name,
})

export const transformEscalationIssue = i => ({
  key: i.key,
  href: `https://platformsh.atlassian.net/browse/${i.key}`,
  summary: i.fields.summary,
  foundation: i.fields.customfield_13179 || '',
  created: i.fields.created,
  category: i.fields.customfield_13279 ? i.fields.customfield_13279.value : '',
  region: i.fields.customfield_13171 ? i.fields.customfield_13171.value : '',
})

export const problemIssueShape = {
  created: string,
  href: string.isRequired,
  key: string.isRequired,
  summary: string.isRequired,
  status: string.isRequired,
  workaround: bool,
}

export const deployIssueShape = {
  href: string.isRequired,
  key: string.isRequired,
  status: string.isRequired,
  summary: string.isRequired,
  stub: bool.isRequired,
}

export const escalationIssueShape = {
  category: string.isRequired,
  created: string,
  foundation: string,
  href: string.isRequired,
  key: string.isRequired,
  region: string.isRequired,
  summary: string.isRequired,
  stub: bool.isRequired,
}

export const getProblemStatus = issue => {
  const pfMap = [
    { jira: 'New Issue', lighthouse: 'New' },
    { jira: 'In Progress', lighthouse: 'In progress' },
    { jira: 'Done', lighthouse: 'Pending release' },
    { jira: 'Duplicate', lighthouse: "Won't fix" },
    { jira: "Won't Fix", lighthouse: "Won't fix" },
    { jira: 'Released Other', lighthouse: 'Released other' },
  ]
  if (issue.deployIssues.length === 0) {
    const match = pfMap.find(m => m.jira === issue.status)
    return match ? match.lighthouse : issue.status
  }
  const deployMap = [
    { jira: 'To Do', lighthouse: 'Release scheduled' },
    { jira: 'In Progress', lighthouse: 'Release scheduled' },
    { jira: 'In Review/Blocked', lighthouse: 'Release scheduled' },
    { jira: 'Done', lighthouse: 'Released' },
  ]
  return issue.deployIssues.reduce((acc, i) => {
    if (acc !== 'Release scheduled' && i.status !== 'Cancelled') {
      const match = deployMap.find(m => m.jira === i.status)
      return match ? match.lighthouse : i.status
    }
    return acc
  }, '')
}

export const inactiveStatuses = ['Released', 'Released other', "Won't fix"]

export const getRelatedKeys = problemIssues => problemIssues.reduce((acc, current) => {
  current.fields.issuelinks.forEach(l => {
    if (l.inwardIssue) {
      acc.push(l.inwardIssue.key)
    }
    if (l.outwardIssue) {
      acc.push(l.outwardIssue.key)
    }
  })
  return acc
}, [])

export const getAllKeys = problemIssues => problemIssues.reduce((acc, current) => {
  acc.push(current.key)
  current.fields.issuelinks.forEach(l => {
    if (l.inwardIssue) {
      acc.push(l.inwardIssue.key)
    }
    if (l.outwardIssue) {
      acc.push(l.outwardIssue.key)
    }
  })
  return acc
}, [])

export const getDeployIssues = i => (i.fields.issuelinks
  ? i.fields.issuelinks
    .filter(l => l.inwardIssue && l.type.inward === 'is blocked by')
    .map(l => l.inwardIssue)
  : [])

export const getEscalationIssues = i => (i.fields.issuelinks
  ? i.fields.issuelinks
    .filter(l => l.outwardIssue && l.type.outward === 'causes')
    .map(l => l.outwardIssue)
  : [])

export const fetch = async (jql, startAt = 0) => {
  const jiraCloudId = process.env.JIRA_CLOUD_ID || '02aa9fe9-2c9b-4a3c-b3d0-a10531ecd726'
  const url = `https://api.atlassian.com/ex/jira/${jiraCloudId}/rest/api/3/search`
  const config = { params: { jql, startAt, maxResults: 1000 } }
  const token = await getAccessToken()
  config.headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
  }
  try {
    const { data } = await axios.get(url, config)
    return data
  } catch (e) {
    if (e.response && e.response.status === 401) {
      // eslint-disable-next-line no-console
      console.log('unauthorized, logging out ...')
      sessionStorage.removeItem('atlassianEmail')
      sessionStorage.removeItem('atlassianToken')
    }
    return Promise.reject(e.response)
  }
}

export const fetchProblemIssues = async updateIssues => {
  let startAt = 0
  let maxResults = 0
  let total = 0
  let issues = []
  do {
    // eslint-disable-next-line no-await-in-loop
    ({ startAt, maxResults, total, issues } = await fetch('type = "Problem"', startAt))
    updateIssues(issues, total)
  } while (startAt + maxResults < total)
}

export const fetchRelatedIssues = (problemIssues, updateIssues) => {
  const keys = problemIssues.reduce((acc, current) => {
    current.fields.issuelinks.forEach(l => {
      if (l.inwardIssue) {
        acc.push(l.inwardIssue.key)
      }
      if (l.outwardIssue) {
        acc.push(l.outwardIssue.key)
      }
    })
    return acc
  }, [])

  let startAt = 0
  let maxResults = 0
  let total = 0
  let issues = []
  do {
    ({ startAt, maxResults, total, issues } = fetch(`key in ("${keys.join('", "')}")`, startAt))
    updateIssues(issues, total)
  } while (startAt + maxResults < total)
}
