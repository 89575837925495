import { useEffect } from 'react'
import { useRecoilCallback } from 'recoil'
import { getAccessToken } from '../helpers/auth'
import { fetch, getAllKeys, getRelatedKeys } from '../helpers/jira'
import { fetchZendeskTickets, getUri } from '../helpers/zendesk'
import {
  progressState,
  problemIssuesState,
  relatedIssuesState,
  zendeskTicketsState,
} from '../libs/state'

export default function TicketsInitialLoader() {
  const doAuth = () => {
    const accessToken = localStorage.getItem('atlassianAccessToken') || getAccessToken()
    if (!accessToken) return
    if (accessToken === 'error') {
      alert('Error authenticating with JIRA. Try again, or contact a developer.')
    }
  }

  useEffect(() => {
    doAuth()
  }, [])

  const loadTickets = useRecoilCallback(({ set, snapshot }) => async () => {
    let issues = []
    let zdTickets = []
    let relatedIssues = []
    await snapshot.getPromise(problemIssuesState)
    await snapshot.getPromise(relatedIssuesState)
    await snapshot.getPromise(zendeskTicketsState)
    const getProblemIssues = async (startAt = 0) => {
      const { startAt: resultStartAt, maxResults, total, issues: resIssues } = await fetch(
        'type = "Problem"',
        startAt,
      )
      issues = issues.concat(resIssues)
      if (resultStartAt + maxResults >= total) {
        set(progressState, 50)
      } else {
        set(progressState, 30)
        await getProblemIssues(startAt + maxResults)
      }
    }
    await getProblemIssues()
    const getZendeskTickets = async zdNext => {
      let uri = zdNext
      if (!uri) {
        const keys = getAllKeys(issues)
        uri = getUri(keys)
      }
      const { tickets, next } = await fetchZendeskTickets(uri)
      zdTickets = zdTickets.concat(tickets)
      if (next) {
        set(progressState, prevProgress => (prevProgress < 95 ? prevProgress + 4 : prevProgress))
        await getZendeskTickets(next)
      } else {
        set(progressState, prevProgress => (prevProgress < 95 ? prevProgress + 4 : prevProgress))
      }
    }
    const getRelated = async (relatedChunkStart = 0, relatedStartAt = 0) => {
      let tmpRelatedChunkStart = relatedChunkStart
      let tmpRelatedStartAt = relatedStartAt
      const keys = getRelatedKeys(issues)
      if (keys.length === 0) {
        return
      }
      const relatedChunk = keys.slice(relatedChunkStart, relatedChunkStart + 200)
      const { startAt, maxResults, total, issues: resIssues } = await fetch(
        `key in ("${relatedChunk.join('", "')}")`,
        relatedStartAt,
      )
      relatedIssues = relatedIssues.concat(resIssues)
      if (startAt + maxResults > total) {
        if (relatedChunkStart + 200 > keys.length) {
          set(progressState, prevProgress => (prevProgress < 95 ? prevProgress + 4 : prevProgress))
          return
        }
        tmpRelatedChunkStart += 200
        tmpRelatedStartAt = 0
      } else {
        tmpRelatedStartAt = startAt + maxResults
      }
      set(progressState, prevProgress => (prevProgress < 95 ? prevProgress + 4 : prevProgress))
      await getRelated(tmpRelatedChunkStart, tmpRelatedStartAt)
    }

    await Promise.all([getZendeskTickets(), getRelated()])
    set(problemIssuesState, issues)
    set(relatedIssuesState, relatedIssues)
    set(zendeskTicketsState, zdTickets)
    set(progressState, 100)
  })

  useEffect(() => {
    loadTickets()
  }, [])

  return null
}

TicketsInitialLoader.propTypes = {}
