import React from 'react'
import styled from 'styled-components'

const Icon = styled.svg`
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  fill: currentColor;
`

function FilterIcon(props) {
  return (
    <Icon {...props} aria-labelledby="filter-icon">
      <rect width="16" height="2" rx="1" />
      <rect x="2" y="5" width="12" height="2" rx="1" />
      <rect x="5" y="10" width="6" height="2" rx="1" />
    </Icon>
  )
}

export default FilterIcon
