import React, { useState } from 'react'
import styled from 'styled-components'
import { arrayOf, bool, shape } from 'prop-types'

import ReactTooltip from 'react-tooltip'
import {
  deployIssueShape,
  escalationIssueShape,
  inactiveStatuses,
  problemIssueShape,
} from '../helpers/jira'
import IssueTable from './IssueTable'
import SettingLine from './SettingLine'
import formatDate from '../libs/formatDate'
import TicketTable from './TicketTable'
import { zendeskShape } from '../helpers/zendesk'
import { semiBoldAlias } from '../libs/theme'
import StatusLozenge from './StatusLozenge'
import DeployTable from './DeployTable'
import Placeholder from './Placeholder'

const InfoLayout = styled.div`
  width: 100%;
  display: flex;
  font-size: 13px;
  color: #5f5e70;
  align-items: center;
  padding: 0 32px;
  ${semiBoldAlias()};
  .tooltip-content {
    position: absolute;
    bottom: -3rem;
    background: black;
    white-space: break-spaces;
    padding: 0.5rem;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    display: none;
  }
  &.inactive {
    > div {
      opacity: 0.8;
    }
  }
  .key {
    flex: 0 0 10%;
    font-size: 15px;
    font-weight: bold;
  }
  .summary {
    color: #302f45;
    flex: 1 1 100%;
    max-lines: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 15px;
    display: flex;
    padding-right: 32px;
    .text {
      flex: 0 1 auto;
    }
    .workaround {
      flex: 0 0 45px;
      padding-left: 16px;
      .workaround-lozenge {
        cursor: pointer;
        background: #f0f2f5;
        color: #98a0ab;
        border-radius: 2px;
        padding: 0 8px;
        line-height: 24px;
        font-size: 13px;
        display: inline-block;
      }
    }
  }
  .type {
    flex: 0 0 11%;
    overflow: hidden;
    padding-right: 8px;
    > div {
      width: fit-content;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .tickets {
    flex: 0 0 6.8%;
  }
  .escalations {
    flex: 0 0 7%;
  }
  .total {
    flex: 0 0 7%;
  }
  .created {
    flex: 0 0 11%;
  }
  .status {
    flex: 0 0 11.5%;
  }
  @media (max-width: 1200px) {
    .key {
      flex: 0 0 10%;
    }
    .type {
      flex: 0 0 11%;
    }
    .tickets {
      flex: 0 0 8%;
    }
    .escalations {
      flex: 0 0 8%;
    }
    .total {
      flex: 0 0 8%;
    }
    .created {
      flex: 0 0 13%;
    }
    .status {
      flex: 0 0 11.5%;
    }
  }
`

const BodyLayout = styled.div`
  padding: 0 0 8px;
  h6 {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    margin-top: 24px;
  }
  .table-row-header .cell {
    color: #5f5e70;
  }
  .key,
  .id,
  .ticket {
    flex: 0 0 calc(15% - 32px);
    padding-left: 16px;
  }
  .default,
  .labels {
    flex: 0 0 calc(10% - 42px);
  }
  .table-row {
    color: #98a0ab;
    .title,
    .summary {
      font-size: 15px;
      color: #38485e;
    }
    &.highlit,
    &:hover {
      color: #5f5e70;
      background: #e7f0ff;
      svg path {
        fill: #4a495e;
      }
    }
    .linked {
      //  align rightish?
    }
  }
  .title,
  .summary {
    flex: 1 1 auto;
    max-lines: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .cloud,
  .category {
    flex: 0 0 9%;
  }
  .region {
    flex: 0 0 12%;
  }
  .support-plan,
  .foundation {
    flex: 0 0 11%;
  }
  .created {
    flex: 0 0 calc(11.5% - 58px + 32px);
  }
  .linked {
    flex: 0 0 58px;
    padding-right: 16px;
  }
  .status {
    flex: 0 0 calc(11.5% + 32px);
  }
  @media (max-width: 1200px) {
    .key,
    .id,
    .ticket {
      flex: 0 0 calc(16% - 32px);
    }
    .title,
    .summary {
      flex: 0 0 34.5%;
    }
    .cloud,
    .category {
      flex: 0 0 11%;
    }
    .region {
      flex: 0 0 14%;
    }
    .support-plan,
    .foundation {
      flex: 0 0 13%;
    }
    .created {
      flex: 0 0 calc(11.5% - 58px + 32px);
    }
    .linked {
      flex: 0 0 58px;
    }
  }
`

const ProblemItem = ({ problemIssue, loading }) => {
  const [highlitEscalation, highlightEscalation] = useState([])
  const [highlitZendesk, highlightZendesk] = useState([])
  const [expanded, expand] = useState(false)
  return (
    <SettingLine
      key={problemIssue.key}
      id={`issue-${problemIssue.key}`}
      className={inactiveStatuses.includes(problemIssue.status) ? 'inactive' : ''}
      info={(
        <InfoLayout className={inactiveStatuses.includes(problemIssue.status) ? 'inactive' : ''}>
          <div className="key">
            <a
              href={problemIssue.href}
              className="jira-title"
              target="_blank"
              rel="noopener noreferrer"
            >
              {problemIssue.key}
            </a>
          </div>
          <div className="type">
            <div
              className="text"
              data-for={`labels-${problemIssue.key}`}
              data-tip={problemIssue.labels.join(', ')}
            >
              {problemIssue.labels.length ? problemIssue.labels[0] : ''}
              {problemIssue.labels.length && problemIssue.labels.length > 1
                ? ` + ${problemIssue.labels.length - 1}`
                : ''}
              <ReactTooltip
                id={`labels-${problemIssue.key}`}
                place="bottom"
                type="dark"
                effect="solid"
              />
            </div>
          </div>
          <div className="summary">
            <span
              className="text"
              data-for={`summary-${problemIssue.key}`}
              data-tip={problemIssue.summary}
            >
              {problemIssue.summary}
              <ReactTooltip
                id={`summary-${problemIssue.key}`}
                place="bottom"
                type="dark"
                effect="solid"
              />
            </span>
            {problemIssue.workaround && (
              <span className="workaround">
                <span
                  className="workaround-lozenge"
                  data-tip="Workaround available"
                  data-for={`workaround-${problemIssue.key}`}
                >
                  W
                </span>
                <ReactTooltip
                  id={`workaround-${problemIssue.key}`}
                  place="bottom"
                  type="dark"
                  effect="solid"
                />
              </span>
            )}
          </div>
          <div className="tickets">
            {loading ? (
              <Placeholder width="24px" className={expanded ? 'light' : ''} />
            ) : (
              problemIssue.zendeskTickets.length
            )}
          </div>
          <div className="escalations">{problemIssue.escalationIssues.length}</div>
          <div className="total">
            {loading ? (
              <Placeholder width="24px" className={expanded ? 'light' : ''} />
            ) : (
              problemIssue.zendeskTickets.length + problemIssue.escalationIssues.length
            )}
          </div>
          <div className="created">{formatDate(problemIssue.created)}</div>
          <div className="status">
            {loading ? (
              <Placeholder width="83px" className={expanded ? 'light' : ''} />
            ) : (
              <StatusLozenge status={problemIssue.status} />
            )}
          </div>
        </InfoLayout>
      )}
      isOpen={expanded}
      openText="View"
      onClick={() => {
        expand(!expanded)
      }}
    >
      {expanded && (
        <BodyLayout>
          {problemIssue.deployIssues.length > 0 && (
            <DeployTable issues={problemIssue.deployIssues} />
          )}
          <TicketTable
            tickets={problemIssue.zendeskTickets}
            highlit={highlitZendesk}
            highlight={highlightEscalation}
            loading={loading}
          />
          <IssueTable
            issues={problemIssue.escalationIssues}
            highlit={highlitEscalation}
            highlight={highlightZendesk}
            loading={loading}
          />
        </BodyLayout>
      )}
    </SettingLine>
  )
}

ProblemItem.propTypes = {
  problemIssue: shape({
    ...problemIssueShape,
    deployIssues: arrayOf(shape(deployIssueShape)),
    escalationIssues: arrayOf(shape(escalationIssueShape)),
    zendeskTickets: arrayOf(shape(zendeskShape)),
  }).isRequired,
  loading: bool.isRequired,
}

export default ProblemItem
