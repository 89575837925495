import React from 'react'
import styled from 'styled-components'

import { RecoilRoot } from 'recoil'
import PlatformIcon from './icons/PlatformIcon'
import Lighthouse from './Lighthouse'
import { semiBoldAlias } from './libs/theme'

if (process.env.PLATFORM_TREE_ID) {
  console.log(`Build ID: %c${process.env.PLATFORM_TREE_ID}`, 'color: green') // eslint-disable-line no-console
}

const AppLayout = styled.div`
  background: #f0f2f5;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const HeaderLayout = styled.header`
  height: 64px;
  padding: 16px 40px;
  background: #1a192b;
  color: #fff;
  display: flex;
  box-sizing: border-box;
  svg {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
  }
  h1 {
    font-size: 12px;
    margin-left: 24px;
    ${semiBoldAlias()};
    flex: 1 1 auto;
  }
  button {
    float: right;
    background: none;
    border: none;
    color: #f7f7f9;
    font-size: 1em;
    font-weight: bold;
    margin: 20px;
  }
`

const App = () => (
  <RecoilRoot>
    <AppLayout>
      <HeaderLayout>
        <PlatformIcon />
        <h1>Lighthouse </h1>
      </HeaderLayout>
      <Lighthouse />
    </AppLayout>
  </RecoilRoot>
)

export default App
