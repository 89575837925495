import React from 'react'
import { arrayOf, shape } from 'prop-types'
import styled from 'styled-components'

import Table from './Table/Table'
import TableRow from './Table/TableRow'
import TableCell from './Table/TableCell'
import StatusLozenge from './StatusLozenge'
import { deployIssueShape } from '../helpers/jira'

const TableWrapper = styled.div`
  padding: 0 32px;
`

const DeployTable = ({ issues }) => (
  <TableWrapper>
    <h6>{`Deploy Tickets (${issues.length})`}</h6>
    <Table headers={['Ticket', 'Title', 'Status']} className="deploy-table">
      {issues.map(({ href, key, summary, status }, i) => (
        <TableRow
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className="table-row"
        >
          <TableCell className="key">
            <a
              href={href}
              className="jira-title"
              target="_blank"
              rel="noopener noreferrer"
            >
              {key}
            </a>
          </TableCell>
          <TableCell className="summary">{summary}</TableCell>
          <TableCell className="status">
            <StatusLozenge status={status} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  </TableWrapper>
)

DeployTable.propTypes = { issues: arrayOf(shape(deployIssueShape)).isRequired }

export default DeployTable
