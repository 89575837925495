/* eslint-disable camelcase */
import axios from 'axios'
import queryString from 'query-string'
import Client from 'platformsh-client'
import hash from 'hash-it'
import toQuerystring from 'to-querystring'

const getApiConfig = () => ({ client_id: 'lighthouse@platform' })

export const client = new Client(getApiConfig())

export const getAccessToken = async () => {
  const accessToken = JSON.parse(localStorage.getItem('atlassianAccessToken'))
  if (accessToken && accessToken.expires > Date.now()) {
    return accessToken.access_token
  }
  if (accessToken && accessToken.refresh_token) {
    try {
      const { data } = await axios.get('/api/reauthorize', { params: { refresh_token: accessToken.refresh_token } })
      localStorage.setItem('atlassianAccessToken', JSON.stringify(data) || 'error')
      return data.access_token
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
      return false
    }
  }
  const query = queryString.parse(window.location.search)
  const authCode = query.code
  if (!authCode) {
    const { id } = await client.getAccountInfo()
    const encodedQuery = toQuerystring({
      audience: 'api.atlassian.com',
      client_id: process.env.REACT_APP_CLIENT_ID || 'ssnlrkD6k03XiaDawRDUyALRTr3jJkr5',
      prompt: 'consent',
      redirect_uri: process.env.REACT_APP_REDIRECT_URI || 'https://lighthouse.pltfrm.sh/jira-connect',
      response_type: 'code',
      scope: 'read:jira-user read:jira-work write:jira-work offline_access',
      state: hash(id),
    })
    window.location.href = `https://auth.atlassian.com/authorize?${encodedQuery}`
    return false
  }
  try {
    const client_secret = process.env.REACT_APP_CLIENT_SECRET || 'BPpVsI5OjfQj1SD8BmwoeM_f09eiHYM8GDGkk1FSANPkf3-XMRTdygtO_Ox8OZY5'
    const client_id = process.env.REACT_APP_CLIENT_ID || 'ssnlrkD6k03XiaDawRDUyALRTr3jJkr5'
    const uri = 'https://auth.atlassian.com/oauth/token'
    const redirect_uri = process.env.REACT_APP_REDIRECT_URI || 'https://lighthouse.pltfrm.sh/jira-connect'
    const { data } = await axios.post(
      uri,
      {
        grant_type: 'authorization_code',
        client_id,
        client_secret,
        code: authCode,
        redirect_uri,
      },
      { headers: { 'Content-Type': 'application/json' } },
    )
    data.expires = Date.now() + data.expires_in
    localStorage.setItem('atlassianAccessToken', JSON.stringify(data) || 'error')
    window.location.href = '/'
    return data.access_token
  } catch (e) {
    let error = e
    // Error
    if (e.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      error = { data: e.response.data, status: e.response.status, headers: e.response.headers }
    } else if (e.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      error = e.request
    } else if (e.message) {
      // Something happened in setting up the request that triggered an Error
      error = e.message
    }
    // eslint-disable-next-line no-console
    console.log(error)
  }
  return ''
}
